import { Fragment, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Container, Form, Image, ListGroup, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "wouter";
import { SALESPORTAL_WEBAPI_URL } from "../../../../../Api/constants";
import ClientActivity from "./Modals/ClientActivity";

const Order = (props) => {

    const [order, setOrder] = useState();
    const [items, setItems] = useState();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [modalAction, setModalAction] = useState("create");

    useEffect(() => {

        fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/orders/get-secret/" + props.orderNumber, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            const json = await res.json();

            const orderSecret = json.orderSecret;



            fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/orders/" + orderSecret, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                const json = await res.json();
                if (!json.error) {
                    setOrder(json);
                } else {
                    alert("Error connecting to Caseful Store.")
                }
            })

            fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/orders/" + orderSecret + "/line-items", {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                const json = await res.json();
                if (!json.error) {
                    setItems(json);
                } else {
                    alert("Error connecting to Caseful Store.")
                }
            })


        })
    }, [props.orderNumber])

    const displayLineItems = () => {
        return items.lineItems.map(i => (
            <ListGroup.Item key={i.id}>
                <Row className="mt-2">

                    <Col md="2" xs="4">
                        <Image fluid={true} src={i.thumbnailUrl} />
                    </Col>
                    <Col md="auto" xs="auto">
                        <div className="mb-3">
                            <b>{i.name} - {i.variantName}</b>
                            <p>${i.price.toFixed(2)} | Qty: {i.quantity}</p>
                            <div>
                                {i.deliveryTrackingUrl && <a href={i.deliveryTrackingUrl} target="_blank"><Button variant="primary" size="sm">Track order</Button></a>}
                            </div>
                        </div>
                    </Col>

                </Row>

            </ListGroup.Item>
        ))
    }

    if (order && items) {
        return (
            <Fragment>
                <Container className="mt-5">
                    <div>
                        <Link href="/orders"><Button variant="light" className="mb-5">Back to Orders</Button></Link>
                        <div className="d-flex justify-content-between">

                            <h2>Order {order.orderNumber}</h2>

                        </div>
                        <hr />

                        <Row>
                            <Col xs={12} md={6}>

                                <ListGroup className="mb-4">
                                    <ListGroup.Item active>
                                        Order Summary
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Order Status</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                {order.status == "draft" && <Badge bg="secondary">Draft</Badge>}
                                                {order.status == "checkout" && <Badge bg="primary">Checkout</Badge>}
                                                {order.status == "paid" && <Badge bg="success">Paid</Badge>}

                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Order Paid At</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.paidAt}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Delivery Method</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.deliveryMethod}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Delivery Min Days</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.deliveryMinDays}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Delivery Max Days</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.deliveryMaxDays}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Gift Message</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.giftMessage}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>



                                </ListGroup>

                                <ListGroup className="mb-4">
                                    <ListGroup.Item active>
                                        Client Summary
                                    </ListGroup.Item>


                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Client Session</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.client.clientSession}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Client IP</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.client.clientIp}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Client Referrer</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.client.clientReferrer}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Client User Agent</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.client.clientUserAgent}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <div className="mt-2 mb-2">
                                           <Button variant="primary" size="sm" onClick={() => { setModalAction("clientActivity"); handleShow()}}>View client activity</Button>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>

                                <ListGroup className="mb-4">
                                    <ListGroup.Item active>
                                        Delivery Address
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Address 1</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.address.address1}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Address 2</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.address.address2}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>City</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.address.city}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>State</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.address.state}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Country</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.address.country}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Postcode</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.address.postcode}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <div className="mt-2 mb-2">
                                            <a href={`https://google.com/search?q=${order.address.address1} ${order.address.city} ${order.address.state} ${order.address.country} ${order.address.postcode}`} target="_blank"><Button variant="primary" size="sm">Search Google for this address</Button></a>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>

                                <ListGroup className="mb-4">
                                    <ListGroup.Item active>
                                        Line Items
                                    </ListGroup.Item>

                                    {displayLineItems()}

                                </ListGroup>
                            </Col>

                            <Col xs={12} md={6}>
                                <ListGroup className="mb-4">
                                    <ListGroup.Item active>
                                        Customer
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Name</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.customer.firstName} {order.customer.lastName}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Email Address</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.customer.email}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Phone Number</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.customer.phone}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Accepts commercial communications</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <Form.Check type="checkbox" checked={order.customer.acceptsCommercialCommunications} disabled />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <div className="mt-2 mb-2">
                                            <Link href={"/customers/" + order.customerId}><Button variant="primary" size="sm">Go to customer</Button></Link>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>

                                <ListGroup className="mb-4">
                                    <ListGroup.Item active>
                                        Payment Summary
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Subtotal</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>${items.price.subtotal.toFixed(2)}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Delivery</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>${items.price.delivery.toFixed(2)}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    {items.price.discounts > 0 &&
                                        <ListGroup.Item>
                                            <Row>
                                                <Col xs={4}>
                                                    <b>Savings</b>
                                                </Col>

                                                <Col xs={"auto"}>
                                                    <p>${items.price.discounts.toFixed(2)}</p>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    }

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Total</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <b>${items.price.total.toFixed(2)}</b>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                </ListGroup>

                                <ListGroup className="mb-4">
                                    <ListGroup.Item active>
                                        Transaction Summary
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Gateway</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.transactionGateway}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Store Currency</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.storeCurrency}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col xs={4}>
                                                <b>Stripe PaymentIntent</b>
                                            </Col>

                                            <Col xs={"auto"}>
                                                <p>{order.paymentIntent}</p>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>


                                </ListGroup>
                            </Col>


                        </Row>

                    </div>
                </Container>

                <Modal show={show} onHide={handleClose} centered>


                    {modalAction == "clientActivity" && <ClientActivity handleClose={handleClose} session={order.client.clientSession} />}




                </Modal>
            </Fragment>
        )
    }
}

export default Order;