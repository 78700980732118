import { useEffect } from "react";
import { useLocation } from "wouter";
import { IDSERVICE_WEBAPI_URL } from "../../../Api/constants";


const Auth = () => {
    const [location, setLocation] = useLocation();

    useEffect(() => {
        fetch(IDSERVICE_WEBAPI_URL + "/idservice/signinwithtoken", {
            method: "GET",
            credentials: "include",
            credentials: 'include'
        }).then(async res => {
            let json = await res.json();
            if (json.code == 0) {
                setLocation("/");

            } else {
                setLocation("/");
            }

        })

    }, [])
    return (
        <div></div>
    )
}

export default Auth;