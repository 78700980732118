import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Image, ListGroup, ListGroupItem, Row, Table } from "react-bootstrap";
import { Link } from "wouter";
import { SALESPORTAL_WEBAPI_URL } from "../../../Api/constants";

const Customers = () => {

    const [searchCustomerFirstName, setSearchCustomerFirstName] = useState("");
    const [searchCustomerLastName, setSearchCustomerLastName] = useState("");
    const [searchCustomerEmail, setSearchCustomerEmail] = useState("");
    const [searchCustomerPhone, setSearchCustomerPhone] = useState("");

    const [customers, setCustomers] = useState();

    const [page, setPage] = useState(1);
    const [loadingPage, setLoadingPage] = useState(false);
    const [reachedEnd, setReachedEnd] = useState(false);


    // Fetch customers from API
    useEffect(() => {



        fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/customers/", {

            method: "GET",
            credentials: "include",

        }).then(async res => {


            let response = await res.json();

            setCustomers(response);



        })
    }, [])

    // Display products
    const displayCustomers = (search) => {

        return customers.map(c => (

            <tr>
                <td>{c.name}</td>
                <td>{c.email}</td>
                <td><Link className="cursor-pointer" href={"/customers/" + c.id}>View</Link></td>
            </tr>
        ))
    }

    const searchBy = (query, searchBy) => {
        fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/customers/search-by-" + searchBy + "/" + query, {

            method: "GET",
            credentials: "include",

        }).then(async res => {


            let response = await res.json();

            setCustomers(response);



        })
    }

    const clearSearch = () => {
        fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/customers/", {

            method: "GET",
            credentials: "include",

        }).then(async res => {


            let response = await res.json();

            setCustomers(response);



        })
    }

    return (
        <Container className="mt-5 overflow-x-hidden">
            <div>
                <div className="d-flex justify-content-between">
                    <h2>Customers</h2>
                </div>
                <hr />
            </div>

            <div className="mb-5">

                <Form.Group as={Row}>

                    <Col xs="12" md="auto">
                        <Row className="mb-3">
                            <Col xs="auto">
                                <Form.Control type="text" placeholder="Search by first name" value={searchCustomerFirstName} onChange={(e) => { setSearchCustomerFirstName(e.target.value) }} />
                            </Col>
                            <Col>
                                <Button variant="primary" onClick={() => searchBy(searchCustomerFirstName, "first-name")}>Search</Button>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs="12" md="auto">
                        <Row className="mb-3">
                            <Col xs="auto">
                                <Form.Control type="text" placeholder="Search by last name" value={searchCustomerLastName} onChange={(e) => { setSearchCustomerLastName(e.target.value) }} />
                            </Col>
                            <Col>
                                <Button variant="primary" onClick={() => searchBy(searchCustomerLastName, "last-name")}>Search</Button>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs="12" md="auto">
                        <Row className="mb-3">
                            <Col xs="auto">
                                <Form.Control type="text" placeholder="Search by email" value={searchCustomerEmail} onChange={(e) => { setSearchCustomerEmail(e.target.value) }} />
                            </Col>
                            <Col>
                                <Button variant="primary" onClick={() => searchBy(searchCustomerEmail, "email")}>Search</Button>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs="12" md="auto">
                        <Row className="mb-3">
                            <Col xs="auto">
                                <Form.Control type="text" placeholder="Search by phone" value={searchCustomerPhone} onChange={(e) => { setSearchCustomerPhone(e.target.value) }} />
                            </Col>
                            <Col>
                                <Button variant="primary" onClick={() => searchBy(searchCustomerPhone, "phone")}>Search</Button>
                            </Col>
                        </Row>
                    </Col>
                </Form.Group>

                <Button variant="danger" onClick={() => clearSearch()}>Clear search</Button>
            </div>










            {(customers) && <div className="overflow-x-scroll">
                <Table striped>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Details</th>

                        </tr>
                    </thead>
                    <tbody>
                        {displayCustomers()}
                    </tbody>
                </Table>
            </div>}

        </Container >
    )
}

export default Customers;