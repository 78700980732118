export const APP_ID = process.env.NODE_ENV === "development"
    ? "e53f7ee9-4ed5-11ed-a447-9a4d30abf01b" : "e10cb720-4ed4-11ed-a447-9a4d30abf01b";

export const SALESPORTAL_WEBAPI_URL = process.env.NODE_ENV === "development"
    ? "http://localhost:3001" : "https://casefulwebapi.shopcaseful.com";

export const IDSERVICE_WEBAPI_URL = process.env.NODE_ENV === "development"
    ? "http://localhost:3001" : "https://casefulwebapi.shopcaseful.com";


export const IDSERVICE_AUTHORIZATION_URL = process.env.NODE_ENV === "development"
    ? "http://localhost:3002/auth/authorize" : "https://idservice.shopcaseful.com/auth/authorize";

export const IDSERVICE_SIGNOUT_URL = process.env.NODE_ENV === "development"
    ? "http://localhost:3002/auth/signout" : "https://idservice.shopcaseful.com/auth/signout";

export const METRICSPORTAL_WEBAPI_URL = process.env.NODE_ENV === "development"
    ? "http://localhost:3001" : "https://casefulwebapi.shopcaseful.com";