import { useEffect } from "react";
import { APP_ID, IDSERVICE_AUTHORIZATION_URL } from "../../../Api/constants";


const RedirectToAuthorization = () => {
    useEffect(() => {

        let element = document.createElement('a');

        element.href = IDSERVICE_AUTHORIZATION_URL + "?appId=" + APP_ID;

        element.click();
    }, [])
    return (<div></div>);
}

export default RedirectToAuthorization;