import { Fragment, useEffect, useState } from "react";
import { Badge, ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import { SALESPORTAL_WEBAPI_URL } from "../../../../../../../Api/constants";

const ClientActivity = (props) => {

    const [events, setEvents] = useState();

    useEffect(() => {
        fetch(SALESPORTAL_WEBAPI_URL + "/metricsportal/events/by-session/" + props.session, {

            method: "GET",
            credentials: "include",

        }).then(async res => {


            let response = await res.json();

            setEvents(response);




        })
    }, [])

    const displayEvents = () => {
        return events.map(e => (
            <ListGroupItem>
                <b>{e.event}</b>
                <div>
                    {e.type == "View Product" && <Badge bg="secondary">{e.type}</Badge>}
                    {e.type == "Add to Bag" && <Badge bg="primary">{e.type}</Badge>}
                    {e.type == "Initiate Checkout" && <Badge bg="success">{e.type}</Badge>}
                </div>
                <div>
                    <p className="text-muted">{e.createdAt}</p>
                </div>
                <div>
                    <p className="text-muted">{e.cid}</p>
                </div>
            </ListGroupItem>
        ))
    }

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Client Activity</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <ListGroup>
                    

                    {events && displayEvents()}

                    <ListGroupItem>
                        <b>Session Created</b>
                        <div>
                            <Badge bg="secondary">Session Created</Badge>
                        </div>
                    </ListGroupItem>

                </ListGroup>



            </Modal.Body>
        </Fragment>
    )
}

export default ClientActivity;