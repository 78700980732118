import { Fragment, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { SALESPORTAL_WEBAPI_URL } from "../../../../../Api/constants";

const EditPromotion = (props) => {

    const [saving, setSaving] = useState(false);
    const [promotion, setPromotion] = useState();
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [amount, setAmount] = useState(0);
    const [percentageOff, setPercentageOff] = useState(0);

    useEffect(() => {
        fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/promotions/" + props.promotionId, {

            method: "GET",
            credentials: "include",

        }).then(async res => {


            let response = await res.json();

            setPromotion(response);

            setName(response.name);
            setCode(response.code);
            setAmount(response.amount.toFixed(2));
            setPercentageOff(response.percentageOff);




        })
    }, [])

    const updatePromotion = () => {
        setSaving(true);

        const updatePayload = {
            name: name,
            code: code,
            amount: amount,
            percentageOff: percentageOff
        };

        fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/promotions/" + props.promotionId + "/update", {
            method: "POST",
            credentials: "include",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatePayload),
        }).then(async res => {
            let json = await res.json();
            if (json.success) {
                setSaving(false);
                props.refresh()
                props.handleClose()

            } else {
                setSaving(false);
                console.log("Error");
                alert("Something went wrong.");
            }

        })
    }


    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Edit Promotion</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" value={name} onChange={(e) => { setName(e.target.value) }} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Code</Form.Label>
                        <Form.Control type="text" placeholder="Code" value={code} onChange={(e) => { setCode(e.target.value) }} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Value</Form.Label>
                        <Form.Control type="number" min="0" placeholder="Value" value={amount} onChange={(e) => { setAmount(e.target.value) }} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Percentage Off</Form.Label>
                        <Form.Control type="number" min="0" max="100" placeholder="Percentage Off" value={percentageOff} onChange={(e) => { setPercentageOff(e.target.value) }} />
                    </Form.Group>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" disabled={(!saving && name && code && amount || percentageOff) ? false : true} onClick={updatePromotion}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Fragment>
    )
}

export default EditPromotion;