import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { METRICSPORTAL_WEBAPI_URL } from "../../../Api/constants";

const Metrics = () => {

    const [sales, setSales] = useState();
    const [topProducts, setTopProducts] = useState();
    const [orders, setOrders] = useState();
    const [averageOrderValue, setAverageOrderValue] = useState();
    const [impressions, setImpressions] = useState();
    const [sessions, setSessions] = useState();
    const [clicks, setClicks] = useState();
    const [mostClicked, setMostClicked] = useState();
    const [conversionRate, setConversionRate] = useState();
    const [productViewCount, setProductViewCount] = useState();
    const [productViewRate, setProductViewRate] = useState();
    const [addToBagCount, setAddToBagCount] = useState();
    const [addToBagRate, setAddToBagRate] = useState();
    const [checkoutCount, setCheckoutCount] = useState();
    const [checkoutRate, setCheckoutRate] = useState();
    const [paymentAddedCount, setPaymentAddedCount] = useState();
    const [paymentAddedRate, setPaymentAddedRate] = useState();

    const [lastDays, setLastDays] = useState(1);

    useEffect(() => {

        fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/sales?lastDays=" + lastDays, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            let json = await res.json();
            setSales(json.total);

        })

        fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/top-products?lastDays=" + lastDays, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            let json = await res.json();
            setTopProducts(json);

        })

        fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/orders?lastDays=" + lastDays, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            let json = await res.json();
            setOrders(json.orders);

        })

        fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/average-order-value?lastDays=" + lastDays, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            let json = await res.json();
            setAverageOrderValue(json.averageOrderValue);

        })

        fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/impressions?lastDays=" + lastDays, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            let json = await res.json();
            setImpressions(json.impressions);

        })

        fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/sessions?lastDays=" + lastDays, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            let json = await res.json();
            setSessions(json.sessions);

        })

        fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/clicks?lastDays=" + lastDays, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            let json = await res.json();
            setClicks(json.clicks);

        })

        fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/most-clicked?lastDays=" + lastDays, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            let json = await res.json();
            setMostClicked(json);

        })

        fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/conversion-rate?lastDays=" + lastDays, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            let json = await res.json();
            setConversionRate(json.conversionRate);

        })

        fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/product-view-rate?lastDays=" + lastDays, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            let json = await res.json();
            setProductViewCount(json.conversions);
            setProductViewRate(json.conversionRate);

        })

        fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/add-to-bag-rate?lastDays=" + lastDays, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            let json = await res.json();
            setAddToBagCount(json.conversions);
            setAddToBagRate(json.conversionRate);

        })

        fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/checkout-rate?lastDays=" + lastDays, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            let json = await res.json();
            setCheckoutCount(json.conversions);
            setCheckoutRate(json.conversionRate);

        })

        fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/overview/payment-added-rate?lastDays=" + lastDays, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            let json = await res.json();
            setPaymentAddedCount(json.conversions);
            setPaymentAddedRate(json.conversionRate);

        })


    }, [lastDays])

    const displayTopProducts = () => {
        return topProducts.map((p) => (

            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <p>{p.name}  <p className="text-muted">{p.magnitude.toLocaleString()} sales</p></p>

                </div>
            </div>
        ))
    }


    return (
        <Container className="mt-5" >
            <div>
                <div className="d-flex justify-content-between">
                    <h2>Metrics Overview</h2>
                    <div>
                        <Form.Select onChange={(e) => { setLastDays(e.target.value); console.log(e.target.value) }}>
                            <option value={1}>Today</option>
                            <option value={7}>7 days</option>
                            <option value={14}>14 days</option>
                            <option value={30}>30 days</option>
                            <option value={90}>3 months</option>
                            <option value={180}>6 months</option>
                            <option value={365}>1 year</option>
                            <option value={1000000}>Lifetime</option>
                        </Form.Select>
                    </div>
                </div>
                <hr />


                <Row>
                    <Col md="4" className="mb-3">
                        <Card body className="h-100">
                            <div className="mb-3">
                                <h1>${sales && sales.toFixed(2).toLocaleString()}</h1>
                                <p>Sales Revenue</p>
                            </div>
                      
                        </Card>
                    </Col>

                    <Col md="4" className="mb-3">
                        <Card body className="h-100">
                            <h1>{orders && orders.toLocaleString()}</h1>
                            <p>Orders</p>
                        </Card>
                    </Col>

                    <Col md="4" className="mb-3">
                        <Card body className="h-100">
                            <h1>{impressions && impressions.toLocaleString()}</h1>
                            <p>Impressions</p>
                        </Card>
                    </Col>

                    <Col md="4" className="mb-3">
                        <Card body className="h-100">
                            <h1>{sessions && sessions.toLocaleString()}</h1>
                            <p>Sessions</p>
                        </Card>
                    </Col>

                    <Col md="4" className="mb-3">
                        <Card body className="h-100">
                            <div className="mb-5">
                                <h1>{conversionRate && (conversionRate * 100).toFixed(2)}%</h1>
                                <p>Conversion Rate</p>
                            </div>

                            <div>
                                <p className="text-muted mb-2">Sales Funnel</p>

                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p>Viewed product
                                            <p className="text-muted">{productViewCount && productViewCount.toLocaleString()} sessions</p>
                                        </p>

                                    </div>
                                    <p>{productViewRate && (productViewRate * 100).toFixed(2)}%</p>
                                </div>

                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p>Addded to bag
                                            <p className="text-muted">{addToBagCount && addToBagCount.toLocaleString()} sessions</p>
                                        </p>

                                    </div>
                                    <p>{addToBagRate && (addToBagRate * 100).toFixed(2)}%</p>
                                </div>

                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p>Initiated checkout
                                            <p className="text-muted">{checkoutCount && checkoutCount.toLocaleString()} sessions</p>
                                        </p>

                                    </div>
                                    <p>{checkoutRate && (checkoutRate * 100).toFixed(2)}%</p>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col md="4" className="mb-3">
                        <Card body className="h-100">
                            <h1>${averageOrderValue ? averageOrderValue.toFixed(2).toLocaleString() : 0.00}</h1>
                            <p>Average Order Value</p>
                        </Card>
                    </Col>

                    <Col md="4" className="mb-3">
                        <Card body className="h-100">
                            <h3>Top Products (all time)</h3>
                        {topProducts && displayTopProducts()}
                        </Card>
                    </Col>

                   

                </Row>
            </div>

        </Container>
    )
}

export default Metrics;