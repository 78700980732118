import { Badge, Button, Card, Col, Container, Form, Image, ListGroup, Row, Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link } from "wouter";
import { SALESPORTAL_WEBAPI_URL } from "../../../../../../../Api/constants";


const Overview = (props) => {
    const customer = props.customer;

    const [orders, setOrders] = useState();
    const [address, setAddress] = useState();


    // Fetch orders from API
    useEffect(() => {



        fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/customers/" + props.customerId + "/orders", {

            method: "GET",
            credentials: "include",

        }).then(async res => {


            let response = await res.json();

            setOrders(response);



        })
    }, [])

    // Fetch address from API
    useEffect(() => {



        fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/customers/" + props.customerId + "/addresses", {

            method: "GET",
            credentials: "include",

        }).then(async res => {


            let response = await res.json();

            setAddress(response);



        })
    }, [])

    const displayOrders = () => {
        return orders.map(o => (
            <Link href={"/orders/" + o.orderNumber}>
                <ListGroup.Item className="cursor-pointer">
                    <b>Order {o.orderNumber}</b>
                    <p className="text-muted">${o.subtotal.toFixed(2)} | &nbsp;
                        {o.status == "checkout" && <Badge bg="secondary">Draft</Badge>}
                        {o.status == "paid" && <Badge bg="success">Paid</Badge>}</p>
                </ListGroup.Item>
            </Link>
        ))
    }


    return (

        <Row>


            <Col xs={12} md={6}>
                <ListGroup className="mb-4">
                    <ListGroup.Item active>
                        Customer Summary
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <Row>
                            <Col xs={4}>
                                <b>Name</b>
                            </Col>

                            <Col xs={"auto"}>
                                <p>{customer.name}</p>
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <Row>
                            <Col xs={4}>
                                <b>Email Address</b>
                            </Col>

                            <Col xs={"auto"}>
                                <p>{customer.email}</p>
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <Row>
                            <Col xs={4}>
                                <b>Phone Number</b>
                            </Col>

                            <Col xs={"auto"}>
                                <p>{customer.phone}</p>
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <Row>
                            <Col xs={4}>
                                <b>Accepts commercial communications</b>
                            </Col>

                            <Col xs={"auto"}>
                                <Form.Check type="checkbox" checked={customer.acceptsCommercialCommunications} disabled />
                            </Col>
                        </Row>
                    </ListGroup.Item>


                </ListGroup>

                {address && <ListGroup className="mb-4">
                    <ListGroup.Item active>
                        Most Recent Address
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <Row>
                            <Col xs={4}>
                                <b>Address 1</b>
                            </Col>

                            <Col xs={"auto"}>
                                <p>{address.address1}</p>
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <Row>
                            <Col xs={4}>
                                <b>Address 2</b>
                            </Col>

                            <Col xs={"auto"}>
                                <p>{address.address2}</p>
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <Row>
                            <Col xs={4}>
                                <b>City</b>
                            </Col>

                            <Col xs={"auto"}>
                                <p>{address.city}</p>
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <Row>
                            <Col xs={4}>
                                <b>State</b>
                            </Col>

                            <Col xs={"auto"}>
                                <p>{address.state}</p>
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <Row>
                            <Col xs={4}>
                                <b>Country</b>
                            </Col>

                            <Col xs={"auto"}>
                                <p>{address.country}</p>
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <Row>
                            <Col xs={4}>
                                <b>Postcode</b>
                            </Col>

                            <Col xs={"auto"}>
                                <p>{address.postcode}</p>
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <div className="mt-2 mb-2">
                            <a href={`https://google.com/search?q=${address.address1} ${address.city} ${address.state} ${address.country} ${address.postcode}`} target="_blank"><Button variant="primary" size="sm">Search Google for this address</Button></a>
                        </div>
                    </ListGroup.Item>
                </ListGroup>}


            </Col>

            <Col xs={12} md={6}>
                <ListGroup className="mb-4">
                    <ListGroup.Item active>
                        Orders
                    </ListGroup.Item>

                    {orders && displayOrders()}


                </ListGroup>


            </Col>



        </Row>
    )
}

export default Overview;