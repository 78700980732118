import { Badge, Card, Col, Image, ListGroupItem, Row } from "react-bootstrap";
import { Link } from "wouter";

const OrdersListItem = (props) => {
    return (


        <tr>
            <td>{props.orderNumber}</td>
            <td>{props.status == "checkout" && <Badge bg="secondary">Draft</Badge>}
                {props.status == "paid" && <Badge bg="success">Paid</Badge>}</td>
            <td>${props.subtotal.toFixed(2)}</td>
            <td>{props.name}</td>
            <td><Link className="cursor-pointer" href={"/orders/" + props.orderNumber}>View</Link></td>
        </tr>
    )
}

export default OrdersListItem;