import { Fragment, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { SALESPORTAL_WEBAPI_URL } from "../../../../../Api/constants";

const CreatePromotion = (props) => {

    const [saving, setSaving] = useState(false);

    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [amount, setAmount] = useState(0);
    const [percentageOff, setPercentageOff] = useState(0);

    const addPromotion = () => {
        setSaving(true);

        const updatePayload = {
            name: name,
            code: code,
            amount: amount,
            percentageOff: percentageOff
        };

        fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/promotions/add", {
            method: "POST",
            credentials: "include",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatePayload),
        }).then(async res => {
            let json = await res.json();
            if (json.success) {

                setSaving(false);
                props.refresh();
                props.handleClose()


            } else {
                setSaving(false);
                console.log("Error");
                alert("Something went wrong.");
            }

        })
    }


    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Create Promotion</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" value={name} onChange={(e) => { setName(e.target.value) }} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Code</Form.Label>
                        <Form.Control type="text" placeholder="Code" value={code} onChange={(e) => { setCode(e.target.value) }} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Value</Form.Label>
                        <Form.Control type="number" min="0" placeholder="Value" value={amount} onChange={(e) => { setAmount(e.target.value) }} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Percentage Off</Form.Label>
                        <Form.Control type="number" min="0" max="100" placeholder="Percentage Off" value={percentageOff} onChange={(e) => { setPercentageOff(e.target.value) }} />
                    </Form.Group>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={addPromotion} disabled={(!saving && name && code && amount || percentageOff) ? false : true}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Fragment>
    )
}

export default CreatePromotion;