import { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Image, ListGroup, Row, Table } from "react-bootstrap";
import { Link } from "wouter";
import { SALESPORTAL_WEBAPI_URL } from "../../../Api/constants";
import Modal from 'react-bootstrap/Modal';
import CreatePromotion from "./Modals/CreatePromotion";
import EditPromotion from "./Modals/EditPromotion";


const Promotions = () => {

    const [promotions, setPromotions] = useState();

    const [searchPromotionCode, setSearchPromotionCode] = useState("");

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [modalAction, setModalAction] = useState("create");
    const [editingPromotion, setEditingPromotion] = useState();

    const refresh = () => {

        fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/promotions", {

            method: "GET",
            credentials: "include",

        }).then(async res => {


            let response = await res.json();

            setPromotions(response);


        })
    }

    // Fetch promotions from API
    useEffect(() => {

        refresh();
    }, [])

    const deletePromotion = (id) => {
        fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/promotions/" + id + "/delete", {
            method: "POST",
            credentials: "include",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async res => {
            let json = await res.json();
            if (json.success) {
                refresh();

            } else {
                console.log("Error");
                alert("Something went wrong.");
            }

        })
    }

    const displayPromotions = (search) => {
        if (search) {
            var filteredList = promotions.filter(p => p.code.toLowerCase().includes(search.toLowerCase()));
            return filteredList.map(p => (
                <tr>
                    <td>{p.name}</td>
                    <td>{p.code}</td>
                    <td>${p.amount.toFixed(2)}</td>
                    <td>{p.percentageOff}%</td>
                    <td><a className="cursor-pointer" onClick={() => { setEditingPromotion(p.id); setModalAction("edit"); handleShow() }}>Edit</a></td>
                    <td><a className="link-danger cursor-pointer" onClick={() => deletePromotion(p.id)}>Delete</a></td>
                </tr>
            ))
        } else {
            return promotions.map(p => (
                <tr>
                    <td>{p.name}</td>
                    <td>{p.code}</td>
                    <td>${p.amount.toFixed(2)}</td>
                    <td>{p.percentageOff}%</td>
                    <td><a className="cursor-pointer" onClick={() => { setEditingPromotion(p.id); setModalAction("edit"); handleShow() }}>Edit</a></td>
                    <td><a className="link-danger cursor-pointer" onClick={() => deletePromotion(p.id)}>Delete</a></td>
                </tr>
            ))
        }
    }



    return (
        <Fragment>
            <Container className="mt-5 overflow-x-hidden" >
                <div>
                    <div className="d-flex justify-content-between">
                        <h2>Promotions</h2>
                        <Button variant="primary" onClick={() => { setModalAction("create"); handleShow(); }}>New promotion</Button>
                    </div>
                    <hr />
                </div>

                <Row className="mb-3">
                    <Col md="auto">

                        <Form.Group as={Row}>
                            <Col className="mb-3">

                                <Form.Control type="text" placeholder="Search by code" value={searchPromotionCode} onChange={(e) => { setSearchPromotionCode(e.target.value) }} />
                            </Col>



                        </Form.Group>
                    </Col>


                </Row>

                <div className="overflow-x-scroll">
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Promotion Name</th>
                                <th>Code</th>
                                <th>Value</th>
                                <th>Percentage Off</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {promotions && displayPromotions(searchPromotionCode)}
                        </tbody>
                    </Table>
                </div>

            </Container>

            <Modal show={show} onHide={handleClose} centered>


                {modalAction == "create" && <CreatePromotion handleClose={handleClose} refresh={refresh} />}

                {modalAction == "edit" && <EditPromotion handleClose={handleClose} refresh={refresh} promotionId={editingPromotion} />}


            </Modal>
        </Fragment>
    )
}

export default Promotions;