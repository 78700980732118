import { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Container, Form, Image, ListGroup, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "wouter";
import { SALESPORTAL_WEBAPI_URL } from "../../../../../Api/constants";
import Overview from "./Tabs/Overview";

const Customer = (props) => {

    const [customer, setCustomer] = useState();

    useEffect(() => {



        fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/customers/" + props.customerId, {

            method: "GET",
            credentials: "include",

        }).then(async res => {


            let response = await res.json();

            setCustomer(response);



        })
    }, [])

    if (customer) {
        return (
            <Container className="mt-5">
                <div>
                    <Link href="/customers"><Button variant="light" className="mb-5">Back to Customers</Button></Link>
                    <div className="d-flex justify-content-between">

                        <h2>{customer.name}</h2>

                    </div>
                    <hr />

                    <Tabs
                        defaultActiveKey="overview"
                        className="mb-3"
                    >
                        <Tab eventKey="overview" title="Overview">
                            <Overview customer={customer} customerId={props.customerId} />
                        </Tab>
                        <Tab eventKey="journeys" title="Journeys">

                        </Tab>
                     
                    </Tabs>


                </div>
            </Container>
        )
    }
}

export default Customer;