import { useState } from "react";
import { Button, Card, Col, Container, Form, Image, ListGroup, Row } from "react-bootstrap";
import { SALESPORTAL_WEBAPI_URL } from "../../../../../../../Api/constants";


const ProductDetails = (props) => {

    const [saving, setSaving] = useState(false);

    const [productName, setProductName] = useState(props.product.name);
    const [productCode, setProductCode] = useState(props.product.productCode);
    const [visibility, setVisibility] = useState(props.product.visibility);
    const [violator, setViolator] = useState(props.product.violator);
    const [customizable, setCustomizable] = useState(props.product.customizable);
    const [description, setDescription] = useState(props.product.description);
    const [highlights, setHighlights] = useState(props.product.highlights);
    const [maxUnitsPerOrder, setMaxUnitsPerOrder] = useState(props.product.maxUnitsPerOrder);
    const [canBundle, setCanBundle] = useState(props.product.canBundle);
    const [shippingCenter, setShippingCenter] = useState(props.product.shippingCenter);

    // Update product details

    const updateProductDetails = async () => {

        setSaving(true);

        const updatePayload = {
            name: productName,
            productCode: productCode,
            visibility: visibility,
            description: description,
            highlights: highlights,
            customizable: customizable,
            violator: violator,
            maxUnitsPerOrder: maxUnitsPerOrder,
            canBundle: canBundle,
            shippingCenter: shippingCenter
        };

        console.log(updatePayload)

        try {
            let res = await fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/products/" + props.productCode + "/update", {
                method: "POST",
                credentials: "include",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatePayload),
            });
            if (res.status === 200) {
                setSaving(false);
            } else {
                console.log("Error");
            }
        } catch (err) {
            console.log(err);
            alert("An error occured. " + err);
            setSaving(false);
        }
    }

    return (
        <Container>
            <Form>
                <Row>

                    <Col xs="12" md={4}>

                        <Form.Group className="mb-3">
                            <Form.Label>Product Name</Form.Label>
                            <Form.Control type="text" placeholder="Product Name" value={productName} onChange={(e) => setProductName(e.target.value)} />
                        </Form.Group>

                    </Col>

                    <Col xs="12" md={4}>

                        <Form.Group className="mb-3">
                            <Form.Label>Product Code</Form.Label>
                            <Form.Control type="text" placeholder="Product Code" value={productCode} onChange={(e) => setProductCode(e.target.value)} />
                        </Form.Group>

                    </Col>

                    <Col xs="12" md={4}>

                        <Form.Group className="mb-3">
                            <Form.Label>Product Visibility</Form.Label>
                            <Form.Select value={visibility} onChange={e => setVisibility(e.target.value)}>
                                <option disabled>Select</option>
                                <option value="public">Public</option>
                                <option value="offsale">Offsale</option>
                            </Form.Select>
                        </Form.Group>

                    </Col>

                    <Col xs="12" md={4}>

                        <Form.Group className="mb-3">
                            <Form.Label>Product Violator</Form.Label>
                            <Form.Control type="text" placeholder="Product Violator" value={violator} onChange={(e) => setViolator(e.target.value)} />
                        </Form.Group>

                    </Col>

                    <Col xs="12" md={4}>

                        <Form.Group className="mb-3">
                            <Form.Label>Customizable</Form.Label>
                            <Form.Select value={customizable} onChange={e => setCustomizable(e.target.value)}>
                                <option disabled>Select</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </Form.Select>
                        </Form.Group>

                    </Col>

                </Row>

                <Row>
                    <Col xs="12" md={6}>

                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={6} value={description} onChange={e => setDescription(e.target.value)} />
                        </Form.Group>

                    </Col>

                    <Col xs="12" md={6}>

                        <Form.Group className="mb-3">
                            <Form.Label>Highlights</Form.Label>
                            <Form.Control as="textarea" rows={6} value={highlights} onChange={e => setHighlights(e.target.value)} />
                        </Form.Group>

                    </Col>
                </Row>

                <div className="d-flex justify-content-end">
                    <Button variant="primary" className="mt-3" disabled={saving} onClick={() => updateProductDetails()}>Save changes</Button>
                </div>
            </Form>
        </Container>
    )
}

export default ProductDetails;