// React imports
import { Fragment, useEffect, useState } from 'react';

// Global styles
import './App.css';
import './bootstrap.min.css';

// Routing
import { Link, Redirect, Route } from "wouter";

// Auth
import Auth from './Components/Common/Auth/Auth';
import RedirectToAuthorization from './Components/Common/Auth/RedirectToAuthorization';
import { IDSERVICE_WEBAPI_URL } from './Api/constants';

import Navigation from './Components/Common/Navigation';

// Pages
import Products from './Components/Pages/Products';
import Product from './Components/Pages/Products/Pages/Product';
import Orders from './Components/Pages/Orders';
import Order from './Components/Pages/Orders/Pages/Order';
import Customers from './Components/Pages/Customers';
import Customer from './Components/Pages/Customers/Pages/Customer';
import Account from './Components/Pages/Account';
import Promotions from './Components/Pages/Promotions';
import Metrics from './Components/Pages/Metrics';
import Events from './Components/Pages/Metrics/Pages/Events';
import { Container } from 'react-bootstrap';


const App = () => {

  const [authenticated, setAuthenticated] = useState("loading");

  // Authenticate via IDService API
  useEffect(() => {
    fetch(IDSERVICE_WEBAPI_URL + "/idservice/authorize", {

      method: "GET",
      credentials: "include",

    }).then(async res => {


      let json = await res.json();

      const sessionToken = json.sessionToken;

      if (json.authorized == true) {
        setAuthenticated(true)

      } else {
        setAuthenticated(false);
      }

    })

  }, [])

  // Authentication state
  switch (authenticated) {


    case true: {
      return (
        <div>
          <Navigation />

          <Route path="/products" component={Products} />
          <Route path="/products/:productCode">
            {params => <Product productCode={params.productCode} />}
          </Route>

          <Route path="/collections" />

          <Route path="/promotions" component={Promotions} />

          <Route path="/orders" component={Orders} />
          <Route path="/orders/:orderNumber">
            {params => <Order orderNumber={params.orderNumber} />}
          </Route>

          <Route path="/customers" component={Customers} />
          <Route path="/customers/:customerId">
            {params => <Customer customerId={params.customerId} />}
          </Route>

          <Route path="/metrics" component={Metrics} />
          <Route path="/metrics/events" component={Events} />


          <Route path="/account" component={Account} />

          <Container>
            <div className="mt-5">
              <hr />
              <p className="text-muted"><small>Copyright &copy; {new Date().getFullYear()} Caseful. All rights reserved. <br/> All rights reserved. Information available via this portal is confidential property of Caseful for internal use only, and may not be distributed, replicated, or otherwise disseminated.</small></p>
            </div>
          </Container >
        </div>

      )
    }


    case false: {
      return (
        <Fragment>
          <Route path="/auth" component={Auth} />
          <Route path="/:rest*" component={RedirectToAuthorization} />
        </Fragment>
      )
    }


    case "loading": {
      return (
        <div>Loading SalesPortal...</div>
      )
    }


  }

}

export default App;
