import { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Image, ListGroup, Row, Table } from "react-bootstrap";
import { Link } from "wouter";
import { SALESPORTAL_WEBAPI_URL } from "../../../Api/constants";
import OrdersListItem from "./Components/OrdersListItem";

const Orders = () => {

    const [searchOrderNumber, setSearchOrderNumber] = useState("");
    const [showDrafts, setShowDrafts] = useState(false);

    const [orders, setOrders] = useState("");

    const [page, setPage] = useState(1);
    const [loadingPage, setLoadingPage] = useState(false);
    const [reachedEnd, setReachedEnd] = useState(false);


    // Fetch orders from API
    useEffect(() => {

        setPage(1);

        setLoadingPage(true);
        var parameters = showDrafts ? "?show_drafts=true" : ""

        fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/orders/" + parameters, {

            method: "GET",
            credentials: "include",

        }).then(async res => {


            let response = await res.json();

            setOrders(response);
            setReachedEnd(false);
            setLoadingPage(false);


        })
    }, [showDrafts])

    // Display products
    const displayOrders = (search) => {
        if (search) {
            var filteredList = orders.filter(p => p.orderNumber.toLowerCase().includes(search.toLowerCase()));
            return filteredList.map(p => (
                <OrdersListItem
                    orderNumber={p.orderNumber}
                    name={p.name}
                    subtotal={p.subtotal}
                    status={p.status}
                />
            ))
        } else {
            return orders.map(p => (
                <OrdersListItem
                    orderNumber={p.orderNumber}
                    name={p.name}
                    subtotal={p.subtotal}
                    status={p.status}
                />
            ))
        }
    }

    // Infinite scrolling
    const handleScroll = (e) => {
        var parameters = showDrafts ? "?show_drafts=true" : ""
        if (((window.innerHeight + window.scrollY) >= document.body.offsetHeight) && !loadingPage && !reachedEnd) {
            setLoadingPage(true);
            setPage(page => ++page);



            if (orders) {

                let array = orders;

                fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/orders/" + parameters + "&page=" + page, {

                    method: "GET",
                    credentials: "include",

                }).then(async res => {


                    let response = await res.json();

                    if (response.length > 0) {
                        array.push.apply(array, response);
                        setOrders(array);
                        setLoadingPage(false);
                    } else {
                        setLoadingPage(false);
                        setReachedEnd(true);
                    }


                })


            }

        }
    }

    // Load more
    const loadMore = () => {

        setPage(page => ++page);
        setLoadingPage(true);

        var parameters = showDrafts ? "?show_drafts=true&page=" + (page + 1) : "?page=" + (page + 1);

        if (orders) {

            let array = orders;

            fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/orders/" + parameters, {

                method: "GET",
                credentials: "include",

            }).then(async res => {


                let response = await res.json();

                if (response.length > 0) {
                    array.push.apply(array, response);
                    setOrders([...array]);
                    setLoadingPage(false);
                    console.log(orders)
                } else {
                    setLoadingPage(false);
                    setReachedEnd(true);
                }


            })


        }
    }


    return (
        <Container className="mt-5 overflow-x-hidden" >
            <div>
                <div className="d-flex justify-content-between">
                    <h2>Orders</h2>
                    <div>
                        <Form.Check
                            type={"checkbox"}
                            label={"Show drafts"}
                            value={showDrafts}
                            onClick={() => setShowDrafts(!showDrafts)}
                        />
                    </div>
                </div>
                <hr />
            </div>

            <Row className="mb-3">
                <Col md="auto">

                    <Form.Group as={Row}>
                        <Col className="mb-3">

                            <Form.Control type="text" placeholder="Search orders" value={searchOrderNumber} onChange={(e) => { setSearchOrderNumber(e.target.value) }} />
                        </Col>



                    </Form.Group>
                </Col>


            </Row>



            {(orders) && <Fragment> <div className="overflow-x-scroll">
                <Table striped>
                    <thead>
                        <tr>
                            <th>Order Number</th>
                            <th>Status</th>
                            <th>Subtotal</th>
                            <th>Customer Name</th>
                            <th>Details</th>
                          
                        </tr>
                    </thead>
                    <tbody>
                      {displayOrders(searchOrderNumber)}
                    </tbody>
                </Table>
            </div>

                <div className="d-flex justify-content-center">
                    {(!reachedEnd) && <Button variant="light" onClick={() => { loadMore() }} disabled={loadingPage}>Load more</Button>}
                </div>
            </Fragment>}

        </Container >
    )
}

export default Orders;