
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Image, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { Link } from "wouter";
import { APP_ID, IDSERVICE_SIGNOUT_URL, IDSERVICE_WEBAPI_URL, SALESPORTAL_WEBAPI_URL } from "../../../Api/constants";

const Account = () => {

    const [accountId, setAccountId] = useState("")

    useEffect(() => {
        fetch(IDSERVICE_WEBAPI_URL + "/idservice/profile", {
            method: "GET",
            credentials: "include",
            credentials: 'include'
        }).then(async res => {
            let json = await res.json();
            if (!json.error) {
                setAccountId(json.accountId);

            } else {
                console.log("error")
            }

        })

    }, [])

    const signOut = () => {
        fetch(IDSERVICE_WEBAPI_URL + "/idservice/signout", {
            method: "POST",
            credentials: "include",
            credentials: 'include'
        }).then(async res => {
            let json = await res.json();
            if (json.code == 0) {
                window.location.href = IDSERVICE_SIGNOUT_URL + "?appId=" + APP_ID;

            } else {
                console.log("error")
            }

        })
    }

    return (
        <Container className="mt-5">

            <div>
                <div className="d-flex justify-content-between">
                    <h2>Account</h2>

                </div>
                <hr />
            </div>

            <div>
                <ListGroup className="mb-3">
                    <ListGroupItem>
                        <div className="d-flex justify-content-between mt-3">
                            <p>Signed in as</p>
                            <p className="text-muted">{accountId} (via Caseful ID)</p>
                        </div>
                    </ListGroupItem>
                </ListGroup>

                <Button variant="danger" onClick={() => signOut()}>Sign out</Button>
            </div>
        </Container>
    )
}

export default Account;