import { useEffect, useState } from "react";
import { Badge, Container, ListGroup, ListGroupItem, Tab, Tabs } from "react-bootstrap";
import { METRICSPORTAL_WEBAPI_URL } from "../../../../../Api/constants";

const Events = () => {

    const [event, setEvent] = useState("product-view");
    const [events, setEvents] = useState();
    const [refresh, setRefresh] = useState(false);


    useEffect(() => {
        fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/events/" + event, {
            method: "GET",
            credentials: "include"
        }).then(async res => {
            let json = await res.json();
            setEvents(json);

        })
    }, [event])

    useEffect(() => {
        if (refresh === true) {
            setEvents();
            fetch(METRICSPORTAL_WEBAPI_URL + "/metricsportal/events/" + event, {
                method: "GET",
                credentials: "include"
            }).then(async res => {
                let json = await res.json();
                setEvents(json);
                setRefresh(false);
            })
        }
    }, [refresh])

    const displayEvents = () => {
        var dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        return events.map(e => (
            <ListGroupItem>
                <div className="mt-2">
                    <b>{e.event}</b>
                    <div>
                        {e.type == "View Product" && <Badge bg="secondary">{e.type}</Badge>}
                        {e.type == "Add to Bag" && <Badge bg="primary">{e.type}</Badge>}
                        {e.type == "Initiate Checkout" && <Badge bg="success">{e.type}</Badge>}
                    </div>
                    <div>
                        <p className="text-muted">{new Date(e.createdAt).toLocaleTimeString('en-US') + " " + new Date(e.createdAt).toLocaleDateString("en-US", dateOptions)}</p>
                    </div>
                    <div>
                        <p className="text-muted">{e.cid}</p>
                    </div>
                </div>
            </ListGroupItem>
        ))
    }

    return (
        <Container className="mt-5" >
            <div>
                <div className="d-flex justify-content-between">
                    <h2>Events</h2>

                </div>
                <hr />
            </div>


            <Tabs
                defaultActiveKey="product-view"
                className="mb-3"
                onSelect={(eventKey) => setEvent(eventKey)}
            >
                <Tab eventKey="product-view" title="View Product">
                    <ListGroup>
                        {events && displayEvents()}
                    </ListGroup>
                </Tab>
                <Tab eventKey="add-to-bag" title="Add to Bag">
                    <ListGroup>
                        {events && displayEvents()}

                    </ListGroup>
                </Tab>
                <Tab eventKey="checkout" title="Initiate Checkout">
                    <ListGroup>
                        {events && displayEvents()}
                    </ListGroup>
                </Tab>

            </Tabs>

        </Container>
    )
}

export default Events;