import { Card, Col, Image, ListGroupItem, Row } from "react-bootstrap";
import { Link } from "wouter";

const ProductsListItem = (props) => {
    return (
        <Link href={"/products/" + props.productCode}>
            <ListGroupItem className="cursor-pointer mb-2">
                <Row className="cursor-pointer mt-2">
                    <Col md="1" xs="4">
                        <Image fluid={true} src={props.thumbnailUrl} />
                    </Col>

                    <Col md="auto" xs="auto">
                        <h5>{props.name}</h5>
                        <Row>
                            <Col xs="auto">
                                <p>{props.productCode}</p>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </ListGroupItem>
        </Link>
    )
}

export default ProductsListItem;