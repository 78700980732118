import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Image, ListGroup, Row } from "react-bootstrap";
import { Link } from "wouter";
import { SALESPORTAL_WEBAPI_URL } from "../../../Api/constants";
import ProductsListItem from "./Components/ProductsListItem";

const Products = () => {

    const [searchProductName, setSearchProductName] = useState("");
    const [products, setProducts] = useState("");

    // Fetch products from API
    useEffect(() => {
        fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/products", {

            method: "GET",
            credentials: "include",

        }).then(async res => {


            let response = await res.json();

            setProducts(response);


        })
    }, [])

    // Display products
    const displayProducts = (search) => {
        if (search) {
            var filteredList = products.filter(p => p.name.toLowerCase().includes(search.toLowerCase()));
            return filteredList.map(p => (
                <ProductsListItem
                    key={p.productCode}
                    productCode={p.productCode}
                    name={p.name}
                    thumbnailUrl={p.thumbnailImage}
                />
            ))
        } else {
            return products.map(p => (
                <ProductsListItem
                    key={p.productCode}
                    productCode={p.productCode}
                    name={p.name}
                    thumbnailUrl={p.thumbnailImage}
                />
            ))
        }
    }


    return (
        <Container className="mt-5">
            <div>
                <div className="d-flex justify-content-between">
                    <h2>Products</h2>
                    <Button variant="primary">New product</Button>
                </div>
                <hr />
            </div>

            <Row className="mb-5">
                <Col md="auto">
                
                    <Form.Group as={Row}>
                        <Col className="mb-3">

                            <Form.Control type="text" placeholder="Search products" value={searchProductName} onChange={(e) => { setSearchProductName(e.target.value) }} />
                        </Col>

                   

                    </Form.Group>
                </Col>


            </Row>



            {(products) && <ListGroup variant="flush">{displayProducts(searchProductName)}</ListGroup>}

        </Container>
    )
}

export default Products;