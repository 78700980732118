import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'wouter';

const Navigation = () => {
    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="/">Sales</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link href="/products"><Nav.Link>Products</Nav.Link></Link>
                        <Link href="/collections"><Nav.Link>Collections</Nav.Link></Link>
                        <Link href="/promotions"><Nav.Link>Promotions</Nav.Link></Link>
                        <Link href="/orders"><Nav.Link>Orders</Nav.Link></Link>
                        <Link href="/customers"><Nav.Link>Customers</Nav.Link></Link>
                        <NavDropdown title="Metrics">
                            <Link href="/metrics"><NavDropdown.Item>Overview</NavDropdown.Item></Link>
                            <Link href="/metrics/events"><NavDropdown.Item>Events</NavDropdown.Item></Link>
                        </NavDropdown>
                        <Link href="/account"><Nav.Link>Account</Nav.Link></Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation;