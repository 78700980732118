import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Image, ListGroup, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "wouter";
import { SALESPORTAL_WEBAPI_URL } from "../../../../../Api/constants";
import ProductDetails from "./Tabs/ProductDetails";

const Product = (props) => {

    const [product, setProduct] = useState();

    useEffect(() => {
        fetch(SALESPORTAL_WEBAPI_URL + "/storemanager/products/" + props.productCode, {

            method: "GET",
            credentials: "include",

        }).then(async res => {


            let response = await res.json();

            setProduct(response);


        })
    }, [props.productCode])

    if (product) {
        return (
            <Container className="mt-5">
                <div>
                    <Link href="/products"><Button variant="light" className="mb-5">Back to Products</Button></Link>
                    <div className="d-flex justify-content-between">

                        <h2>{product.name}</h2>

                    </div>
                    <hr />

                    <Tabs
                        defaultActiveKey="details"
                        className="mb-3"
                    >
                        <Tab eventKey="details" title="Product Details">
                           <ProductDetails productCode={props.productCode} product={product} />
                        </Tab>
                        <Tab eventKey="thumbnail" title="Thumbnail">
                           
                        </Tab>
                        <Tab eventKey="variants" title="Variants">
                           
                        </Tab>
                        <Tab eventKey="options" title="Options">
                           
                        </Tab>
                        <Tab eventKey="builderOptions" title="Builder Options">
                           
                        </Tab>
                    </Tabs>
                </div>
            </Container>
        )
    }
}

export default Product;